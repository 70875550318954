















































































import { defineComponent, reactive, watch } from "@vue/composition-api"
import { TypedComponentProps } from "@/vue-props-types"
import { goToRoute } from "@/router"
import RouteName from "@/constants/RouteName"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import TextField from "@/components/textFields/TextField.vue"
import TextArea from "@/components/textAreas/TextArea.vue"
import DefaultButton from "@/components/buttons/DefaultButton.vue"
import ConfirmDialogWithCheckbox from "@/components/dialogs/ConfirmDialogWithCheckbox.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { alertContainer } from "@/containers/AlertContainer"
import { MeetingStructure } from "@/models/Meeting"
import User from "@/models/User"
import WithBackPageLayout from "@/layouts/WithBackPageLayout.vue"
import { Props } from "./types"
import MeetingStructureInput from "@/components/MeetingStructureInput.vue"
import { projectsContainer } from "@/containers/ProjectsContainer"

interface State {
  isFormValid: boolean
  isOpenConfirmDialog: boolean
  isLoading: boolean
  meetingStructure: MeetingStructure
  selectedMembers: Array<User>
  errorMsg: string
}

const propsOptions = {
  id: {
    type: String,
    default: "",
  },
}

export default defineComponent<TypedComponentProps<Props, typeof propsOptions>>(
  {
    props: propsOptions,
    components: {
      I18nFormattedMessage,
      TextField,
      TextArea,
      DefaultButton,
      ConfirmDialogWithCheckbox,
      WithBackPageLayout,
      MeetingStructureInput,
    },
    setup(props) {
      const {
        state: meetingState,
        updateMeetingStructureAsync,
        deleteMeetingStructureAsync,
      } = meetingContainer.useContainer()

      const { getContext } = teamsContextContainer.useContainer()
      const { getProjectAsync } = projectsContainer.useContainer()
      const { showSuccessMessage } = alertContainer.useContainer()

      const state = reactive<State>({
        isFormValid: false,
        isOpenConfirmDialog: false,
        selectedMembers: [],
        isLoading: false,
        meetingStructure: new MeetingStructure(
          meetingState.meetings.value?.find(v => v.id === props.id) ??
            ({} as MeetingStructure)
        ),
        errorMsg: "",
      })

      watch(
        () => meetingState.isLoadingMeetings.value,
        newValue => {
          if (!newValue) {
            state.meetingStructure = new MeetingStructure(
              meetingState.meetings.value?.find(v => v.id === props.id) ??
                ({} as MeetingStructure)
            )
          }
        }
      )

      const onExternalAttendeeChange = (attendees: Array<User>) => {
        const attendeesWithId = attendees.map(t => {
          return new User({ ...t, id: "" })
        })
        state.meetingStructure.users = attendeesWithId
      }

      const onMeetingStructureInput = (mtgStructure: MeetingStructure) => {
        state.meetingStructure = mtgStructure
      }

      const onValidChange = (isValid: boolean) => {
        if (isValid && state.meetingStructure.name) {
          state.isFormValid = true
        } else {
          state.isFormValid = false
        }
      }

      const updateMeeting = async () => {
        if (props.id) {
          state.isLoading = true
          try {
            const context = await getContext()
            const project = await getProjectAsync(context.entityId)
            await updateMeetingStructureAsync(
              project.siteId,
              context.entityId,
              props.id,
              state.meetingStructure
            )
            showSuccessMessage("更新が完了しました")
          } finally {
            state.isLoading = false
          }
        }
      }

      const deleteMeeting = async () => {
        state.isOpenConfirmDialog = false
        if (props.id) {
          state.isLoading = true
          try {
            const context = await getContext()
            await deleteMeetingStructureAsync(context.entityId, props.id)
            showSuccessMessage("削除が完了しました")
            goToRoute({
              name: RouteName.MeetingSeries,
              params: {
                id: "",
              },
            })
          } finally {
            state.isLoading = false
          }
        }
      }

      return {
        state,
        updateMeeting,
        deleteMeeting,
        onMeetingStructureInput,
        onValidChange,
        onExternalAttendeeChange,
      }
    },
  }
)
